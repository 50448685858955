import React from 'react';
import moment from 'moment';
import Layout from '../components/layout';
import { useStaticQuery } from 'gatsby';
import PublicationTile from '../components/publication-tile';


const PublicationsPage = () => {
    const publicationData = useStaticQuery(graphql`
    {
      allMdx(filter: {frontmatter: {published: {eq: true}}}, sort: {fields: frontmatter___date}) {
        nodes {
          excerpt(pruneLength: 100)
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  `);

    return (
        <Layout>
            <section className="section section-height-3 bg-color-grey-scale-1 section-no-border m-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2
                                className="font-weight-normal text-6 mb-5">
                                Our <strong className="font-weight-extra-bold">Publications</strong>
                            </h2>
                        </div>
                    </div>
                    <div className="row recent-posts pb-4 mb-5">
                        {publicationData.allMdx.nodes.map(({ excerpt, frontmatter, fields }, index) => (
                            <div key={index} className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                                <PublicationTile
                                    day={moment(frontmatter.date).format('DD')}
                                    month={moment(frontmatter.date).format('MMM').toUpperCase()}
                                    year={moment(frontmatter.date).format('YYYY').toUpperCase()}
                                    title={frontmatter.title}
                                    excerpt={excerpt}
                                    path={fields.slug}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default PublicationsPage;